import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Aligner = makeShortcode("Aligner");
const Box = makeShortcode("Box");
const Column = makeShortcode("Column");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "cultured-conversations",
      "style": {
        "position": "relative"
      }
    }}>{`Cultured conversations`}<a parentName="h1" {...{
        "href": "#cultured-conversations",
        "aria-label": "cultured conversations permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`After the roaring success of `}<a parentName="p" {...{
        "href": "/build/nfteasy"
      }}>{`nfteasy`}</a>{`, we decided to take a step back and consider NFTs and culture in crypto from a broader and less technical lense. Previous fellows Ben Percifeld and Yulia Babnova graciously agreed to host the first guild in KB5 and you can find recordings of all the insightful and humourous sessions they held below. After they did so, Sparrow Read brought her unique perspective and artistic experience to the mix during KB7, which you can also explore below.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://outland.art/rhea-myers/"
        }}>{`A poetics of permanent transformation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://miro.com/app/board/uXjVOLwz2ic=/"
        }}>{`The one Miro board to rule them all`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=U2xTl5pPHwk"
        }}>{`The best virtual worlds are imagined, not simulated.`}</a></li>
    </ul>
    <p>{`From the first link above, we run straight into the kind of contradictions which, when held honestly and with humble grace, can remake a culture:`}</p>
    <Aligner center mdxType="Aligner">
      <Box sx={{
        textAlign: 'justify',
        width: '70%'
      }} mdxType="Box">
        <p>{`"`}<em parentName="p">{`Titled`}</em>{` sits in the contradiction at the heart of the entire concept of NFTs: we perform ownership of things that cannot be possessed `}{`[...]`}{` For Myers, there is no outside. There is no safe, neutral position from which to assess art, technology, capital, or anything else `}{`[...]`}{` A lot of art exists, according to Myers, simply to assuage the anxiety of liberals who can decode reassuring symbols then go merrily on their way without ever confronting their entanglement in systems they claim to oppose. The transparency and pragmatism of the blockchain, if taken to its logical conclusion, is something that could genuinely threaten this way of being."`}</p>
      </Box>
    </Aligner>
    <h2 {...{
      "id": "recordings",
      "style": {
        "position": "relative"
      }
    }}>{`Recordings`}<a parentName="h2" {...{
        "href": "#recordings",
        "aria-label": "recordings permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <h3 {...{
      "id": "kb7",
      "style": {
        "position": "relative"
      }
    }}>{`KB7`}<a parentName="h3" {...{
        "href": "#kb7",
        "aria-label": "kb7 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Session 1 - Midnight of the Mainstream, Dawn of the Delta`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/pZD0HCE3_8g" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Session 2 - Signs of the Times`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/aE2tbYH_ar4" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Session 3 - The Platform`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/j-a799VnrHY " mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Session 4 - Kernel Services`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/z6pTMJU4NXw" mdxType="Video" />
      </Box>
    </Column>
    <h3 {...{
      "id": "kb5",
      "style": {
        "position": "relative"
      }
    }}>{`KB5`}<a parentName="h3" {...{
        "href": "#kb5",
        "aria-label": "kb5 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`KB5 Session 1 - Art History`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/_yFShVVuA1U" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`KB5 Session 2  - Marketing in Crypto`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/oRMYJ9FoZ2U" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`KB5 Session 3 - Panel Discussion`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/ap0q9k0HtYM" mdxType="Video" />
      </Box>
    </Column>
    <h2 {...{
      "id": "from-the-archives",
      "style": {
        "position": "relative"
      }
    }}>{`From the Archives`}<a parentName="h2" {...{
        "href": "#from-the-archives",
        "aria-label": "from the archives permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Ready-ing the Soil with Nora Bateson & Tom Atlee`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/Fml81oyhKUs" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Sarah Zucker Redefines Ownership`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/v2XqrFkyo68" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Sarah Zucker Redefines... Everything`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/EDMWUppGaIQ" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Community Wisdom Gardening`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/FruLFKfomK4" mdxType="Video" />
      </Box>
    </Column>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      